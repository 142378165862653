import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import Layout from '../../components/Layout';
import { SEO } from '../../components/Seo';

import ContentContainer from '../../styles/content-container';
import FeaturedCard from '../../components/FeaturedCard';
import Block from '../../img/block.svg';
import theme from '../../styles/theme';

export default function Com({ data }) {
  const { edges: latestArticles } = data.allMarkdownRemark;
  // old update to match latest news
  return (
    <Layout>
      <ContentContainer
        sx={{
          marginBottom: '6%',
          [theme.breakpoints.up('lg')]: { display: 'block' },
          [theme.breakpoints.down('lg')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexFlow: 'wrap',
          },
          [theme.breakpoints.down('sm')]: { display: 'block' },
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: '6%', width: '100%' }}>
          Community Posts
        </Typography>
        {latestArticles &&
          latestArticles.length > 1 &&
          latestArticles.map((article) => (
            <FeaturedCard
              href={article.node.frontmatter.path}
              title={article.node.frontmatter.title}
              summary="Summary for article goes here"
              src={Block}
              alt="testing article"
              sx={{
                [theme.breakpoints.up('lg')]: { width: '100%' },
                [theme.breakpoints.down('lg')]: { width: '48%' },
                [theme.breakpoints.down('sm')]: { width: '100%' },
              }}
            />
          ))}
      </ContentContainer>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  {
    allMarkdownRemark(limit: 3) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
          }
        }
      }
    }
  }
`;
